import React, { useEffect, useState } from "react";
import { 
  Container,
  Row,
  Col,
  Image,
} from "react-bootstrap";

import styles from './Type1Mint.module.css';
import certifyWhite from "./images/certify-white.svg";

import WalletConnectorForm from "../WalletConnectorForm";
import ReviewAndClaim from "../ReviewAndClaim";




function Type1Mint(props) {
  // console.log("Type1Mint:::2022-07-06");
  // console.log("props:::2022-07-06", props);
  const params = props.params;
  const payload = props.claimProductResponse;
  const product = props.product;
  var store = props.claimProductResponse.store;
  const nftDetails = props.nftDetails;
  var imageManagementType = "";
  const productExists = payload.productExists;

  const walletAddress = props.walletAddress;

  // State variables:  
  const [url, setURL] = useState(""); // a string that is a link to the NFT's digital asset
  const [mintingStarted, setMintingStarted] = useState(false);
  const [polygonscanTransaction, setPolygonscanTransaction] = useState('');



  if (productExists) {
    imageManagementType = product.image_management_type;
  }
  




  useEffect(() => { //TODO: implement

    fetchData();

    async function fetchData() {
  
      retrieveProduct();
  
      
  
  
      function retrieveProduct() {
        console.log("retrieveProduct():::2022-06-21");
    
        if (productExists) {
          // Prepare product image for NFT:
          if (product.image_details === null) {
            // Split at the question mark and only take the left hand side:
            setURL(product.payload_product.image.src.split('?')[0]);
          } else {
            setURL(`https://${product.image_details.base_url}/${product.image_details.file_path}`);
          }
    
        }
      }
  
    }

  }, [product.image_details, product.payload_product.image.src, productExists]);




  function onMintingStarted(value) {
    console.log('onMintingStarted:::2022-06-28');
    setMintingStarted(value);
  }



  function onPolygonscanTransaction(value) {
    console.log('onPolygonscanTransaction:::2022-06-28');
    setPolygonscanTransaction(value);
  }



  function onShopifyImageChange(image) {
    setURL(image);
  }



  return (
    <Container className="">
      <div className={styles.myFrame}>



        <Row>
          <Col sm={12} md={6}>

            <div className={styles.logoBlock}>
              <Image src={ certifyWhite }></Image>
            </div>

          </Col>
          <Col sm={12} md={6}>

            <div className={`${styles.textRight} ${styles.textCenter768}`}>
              <WalletConnectorForm
                walletAddress={walletAddress}
              ></WalletConnectorForm>
            </div>

          </Col>
        </Row>






        <ReviewAndClaim
          imageManagementType={imageManagementType} 
          mintingStarted={mintingStarted}
          nftDetails={nftDetails}
          onMintingStarted={onMintingStarted}
          onPolygonscanTransaction={onPolygonscanTransaction}
          onShopifyImageChange={onShopifyImageChange}
          params={params}
          polygonscanTransaction={polygonscanTransaction}
          product={product}
          productExists={productExists}
          store={store}
          url={url}
          walletAddress={walletAddress}
        ></ReviewAndClaim>


      </div>
    </Container>
  );

}

export default Type1Mint;