import React from "react";
import styles from './NftDetails.module.css';



function NftDetails(props) {

  const store = props.store;
  const nftDetails = props.nftDetails;


  if (store.fulfilment.nft_details_type === "automatic") {
    return (
      <div className={styles.previewColumn}>

        <div>
          <p className={styles.attributeDescription}>
            *Frames in preview are samples only. Once claimed, your Digital Collectible will include the details below.
          </p>
        </div>

        <div className={styles.nftDetailsAutomatic}>
          <div className=''>
            <p className={styles.attributeTitle}>Main Title (TL on DC)</p>
            <p className={`
              ${styles.attributeDescription}
              ${styles.capitalize}
            `}>
              { nftDetails.automatic.title }
            </p>
          </div>

          <div>
            <p className={styles.attributeTitle}>Sub Title (BL on DC)</p>
            <p className={`
              ${styles.attributeDescription}
              ${styles.capitalize}
            `}>
              { nftDetails.automatic.vendor }
            </p>
          </div>

          <div>
            <p className={styles.attributeTitle}>Secondary Title (TR on DC)</p>
            <p className={`
              ${styles.attributeDescription}
              ${styles.capitalize}
            `}>
              { nftDetails.automatic.display_date }
            </p>
          </div>

          <div>
            <p className={styles.attributeTitle}>Description (Viewable in OpenSea)</p>
            <p className={styles.attributeDescription}>{ nftDetails.automatic.description }</p>
          </div>

        </div>

      </div>
    );    
  }

  if (store.fulfilment.nft_details_type === "manual") {
    return (
      <div className={styles.previewColumn}>

        <div>
          <p className={styles.attributeDescription}>
            *Frames in preview are samples only. Once claimed, your Digital Collectible will include the details below.
          </p>
        </div>

        <div className={styles.nftDetailsManual}>

          <div className=''>
            <p className={styles.attributeTitle}>Main Title (TL on DC)</p>
            <p className={`
              ${styles.attributeDescription}
              ${styles.capitalize}
            `}>
              { nftDetails.manual.title }
            </p>
          </div>
    
          <div>
            <p className={styles.attributeTitle}>Sub Title (BL on DC)</p>
            <p className={`
              ${styles.attributeDescription}
              ${styles.capitalize}
            `}>
              { nftDetails.manual.vendor }
            </p>
          </div>
    
          <div>
            <p className={styles.attributeTitle}>Secondary Title (TR on DC)</p>
            <p className={`
              ${styles.attributeDescription}
              ${styles.capitalize}
            `}>
              { nftDetails.manual.display_date }
            </p>
          </div>

          <div>
            <p className={styles.attributeTitle}>Description (Viewable in OpenSea)</p>
            <p className={styles.attributeDescription}>{ nftDetails.manual.description }</p>
          </div>

        </div>


      </div>
    );    
  }



}

export default NftDetails;