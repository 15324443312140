import Image from 'react-bootstrap/Image';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


// CSS
import styles from "./css/TabbedComponent.module.css";
import "./css/TabbedComponent.css";

// Images
import imageOpensea01 from "./images/opensea-hidden-desktop-p-800.png";
import imageOpensea02 from "./images/opensea-hidden-responsive-p-800.png";
import imageOpensea03 from "./images/opensea-hidden-app-p-800.png";



function TabbedComponent() {
  return (
    <Tabs
      defaultActiveKey="desktop"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="desktop" title="Desktop">

        <div>  
          <p className={`${styles.paragraphDark} ${styles.strong}`}>Desktop browser:</p>
          <p className={`${styles.paragraphDark}`}>1. Visit OpenSea and Connect Wallet.</p>
          <p className={`${styles.paragraphDark}`}>2. Click the 'More' drop down and choose 'Hidden'.</p>
          <p className={`${styles.paragraphDark}`}>3. Click the three-dot menu on the bottom left corner of the item you'd like to unhide, and select Unhide.</p>

          <div style={{height: '20px'}}></div>

          <Image src={imageOpensea01} className={styles.imageOpensea01}></Image>
        </div>

      </Tab>
      <Tab eventKey="mobile" title="Mobile">

        <div>
          <p className={`${styles.paragraphDark} ${styles.strong}`}>Mobile browser:</p>
          <p className={`${styles.paragraphDark}`}>1. Visit OpenSea and Connect Wallet.</p>
          <p className={`${styles.paragraphDark}`}>2. Change the list from 'Collected' to 'Hidden'.</p>
          <p className={`${styles.paragraphDark}`}>3. Tap the three-dot menu on the bottom left corner of the item you'd like to unhide, and select Unhide.</p>

          <div style={{height: '20px'}}></div>

          <Image src={imageOpensea02} className={styles.imageOpensea01}></Image>
        </div>

      </Tab>
      <Tab eventKey="app" title="App">

        <div>
          <p className={`${styles.paragraphDark} ${styles.strong}`}>OpenSea App:</p>
          <p className={`${styles.paragraphDark}`}>1. Tap the Profile icon and Connect Wallet.</p>
          <p className={`${styles.paragraphDark}`}>2. If you have a wallet app like MetaMask, this will now launch. Sign in to your preferred Wallet and accept the OpenSea connection request.</p>
          <p className={`${styles.paragraphDark}`}>3. Once connected, in OpenSea App > Profile, swipe on the menu (located under your username) and tap 'Hidden'.</p>
          <p className={`${styles.paragraphDark}`}>4. To unhide items please use a web browser.</p>

          <div style={{height: '20px'}}></div>

          <Image src={imageOpensea03} className={styles.imageOpensea01}></Image>
        </div>

      </Tab>
    </Tabs>
  );
}

export default TabbedComponent;