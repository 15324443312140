import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { 
  Alert,
  Button,
  Card,
  Image,
  Row,
  Col,
} from "react-bootstrap";
import styles from "./ReviewAndClaim.module.css";
import imageItemsFloating from "./images/items-floating.png";

import ImageManagement from "../../../../pages/Minter/ImageManagement";
import NftDetails from "../NftDetails";
import CustomMetadata from "../CustomMetadata";

require('dotenv').config();

const BASE_URL_API = process.env.REACT_APP_BASE_URL_API;


function RenderMintNftButton(props) {
  // console.log('RenderMintNftButton:::2022-06-28');
  // console.log('props:::2022-06-28', props);

  const navigate = useNavigate();

  const mintingStarted = props.mintingStarted;
  const onMintingStarted = props.onMintingStarted;
  const onPolygonscanTransaction = props.onPolygonscanTransaction;
  const polygonscanTransaction = props.polygonscanTransaction;
  const params = props.params;
  const product = props.product;
  const url = props.url;
  const walletAddress = props.walletAddress;

  if (!mintingStarted) { 
    return (
      <div style={{marginBottom: '15px'}}>
        <Button 
          variant='primary' 
          onClick={() => claimNft()}
          className={styles.addToWallet}
        >ADD TO WALLET</Button>
      </div>
    );
  }

  if (mintingStarted && polygonscanTransaction === '') {
    return (
      <div className="pt-5">
        <Alert variant='warning'>
          Digital Collectible minting has started. Please note this may take a bit of time.
        </Alert>
      </div>
    )
  }



  return (
    <></>
  );


  async function claimNft() {
    // setMintingStarted(true);
    onMintingStarted(true);
  
    // Prepare params to be sent in request:
    const data = {
      product_uuid: params.uuid,
      token: '00ac208a-a4de-11ec-b909-0242ac120002',
      user: 'kain wong',
      imageUrl: url, // Image URL
      // nftName: name,
      // nftDescription: description,
      walletAddress: walletAddress,
      productUuid: params.uuid,
      // purchaseDate: orderCreatedAt,
      // productVendor: productVendor,
    };
    
    // axios.post('https://kw.certifynft.com/nfts/test', data)
    let urlMint = `${ BASE_URL_API }/api/v0/minter/nfts/mint`;
    if (params.status === 'test') urlMint = `${ BASE_URL_API }/api/v0/minter/test/nfts/mint`;

    axios.post(urlMint, data)
      .then(response => {
        console.log('response.data', response.data);
        // setPolygonscanTransaction(response.data.polygonscanTransaction);
        onPolygonscanTransaction(response.data.polygonscanTransaction);
        
        let urlMinted = `/minted?uuid=${product.uuid}`;
        if (params.status === 'test') urlMinted = `/minted?uuid=${product.uuid}&status=test`;

        navigate(urlMinted, { replace: true });
    
      }).catch( error => {
        console.log('error::', error);
        // setMintingStarted(false);
        onMintingStarted(false);
      });
  };


};



function RenderPolygonscanLink(props) {
  console.log('RenderPolygonscanLink:::2022-06-28');
  const polygonscanTransaction = props.polygonscanTransaction;

  if (polygonscanTransaction !== '') {
    return (
      <div className='pt-4'>

        <Alert variant='success'>
          <p><b>Polygonscan Link:</b></p>
          <p className={styles.fontSize14}>Check out your transaction on Polygonscan:</p>
          <a className={styles.polygonscanLink} href={'https://polygonscan.com/tx/' + polygonscanTransaction} target="_blank" rel="noreferrer">https://polygonscan.com/tx/{ polygonscanTransaction }</a>
        </Alert>

      </div>
    )
  }
  return (<></>);
}



function ReviewAndClaim(props) {
  // console.log("ReviewAndClaim:::2022-06-27");
  // console.log("props:::2022-06-27", props);

  const imageManagementType = props.imageManagementType;
  const mintingStarted = props.mintingStarted;
  const nftDetails = props.nftDetails;
  const onPolygonscanTransaction = props.onPolygonscanTransaction;
  const onMintingStarted = props.onMintingStarted;
  const onShopifyImageChange = props.onShopifyImageChange;
  const params = props.params;
  const polygonscanTransaction = props.polygonscanTransaction;
  const product = props.product;
  const productExists = props.productExists;
  const store = props.store;
  const url = props.url;
  const walletAddress = props.walletAddress;


  if (!productExists) {
    return (
      <Row>
        <Col>
          <div style={{textAlign: "center"}}>
            <p>Product does not exist.</p>
          </div>
        </Col>
      </Row>
    )
  }

  if (walletAddress.length > 0) {
    return (
      <>

        <Row>
          <Col>
            <div className={styles.itemsFloating}>
              <Image src={imageItemsFloating} className={styles.itemsFloatingImage}></Image>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="text-center">
              <h1 className={styles.h1}>Your Digital Collectible is ready to claim!</h1>
            </div>
            <div className="text-center" style={{marginBottom: "30px"}}>
              <h4 className={styles.h4}>
                The metaverse and rewards await you...
              </h4>
            </div>
          </Col>
        </Row>



        <Card className={styles.card1}>
          <Card.Body className={styles.cardBody1}>
            <Row>
              <Col>

                <div>
                  <h5 className={styles.headerTitle}>Review and claim</h5>
                </div>

                <div>
                  <p className={`${styles.pDark} ${styles.pDark1}`}>
                  Please review your Digital Collectible details below, and click 'Add to wallet' to transfer the Collectible to your digital wallet.
                  </p>
                </div>

                <div>
                  <p className={styles.pDark}>
                    If any details are incorrect, please contact your store before claiming.
                  </p>
                </div>
              </Col>
            </Row>


            <Row>
              <Col>

                <div className={styles.previewWindow}>
                  <div className={styles.nftPreviewContainer}>
                    <ImageManagement 
                      imageManagementType={imageManagementType} 
                      url={url}
                      onShopifyImageChange={onShopifyImageChange}
                      store={store}
                      product={product}
                    ></ImageManagement>

                    <NftDetails
                      store={store}
                      nftDetails={nftDetails}
                    ></NftDetails>

                    <CustomMetadata
                      product={product}
                      store={store}
                    ></CustomMetadata>
                  </div>
                </div>


              </Col>
            </Row>

            <Row>
              <Col>
                <div>
                  <RenderMintNftButton                
                    mintingStarted={mintingStarted}
                    onMintingStarted={onMintingStarted}
                    onPolygonscanTransaction={onPolygonscanTransaction}
                    params={params}
                    product={product}
                    polygonscanTransaction={polygonscanTransaction}
                    url={url}
                    walletAddress={walletAddress}
                  ></RenderMintNftButton>
                  {/* { renderMintNftButton() } */}
                  {/* { renderPolygonscanLink() } */}
                </div>  
              </Col>
            </Row>

          </Card.Body>
        </Card>







        <Row>
          <Col>
            <div>
              <RenderPolygonscanLink
                polygonscanTransaction={polygonscanTransaction}
              ></RenderPolygonscanLink>
            </div>  
          </Col>
        </Row>


      </>
    );
  }


  return (<></>);

}

export default ReviewAndClaim;