import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import KWNavbar from '../../components/organisms/KWNavbar';

// import './App.css';
// import Minter from './Minter'

function Home() {
  return (
    <div className="App">
      {/* <Minter></Minter> */}

      <KWNavbar
        brand="Certify NFT"
      ></KWNavbar>

      <Container>
        <Row>
          <Col>
            <div className='text-center pt-5'>
              <h1>Home Page!</h1>
            </div>
          </Col>
        </Row>
      </Container>

    </div>
  );

}

export default Home;
