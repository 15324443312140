import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";


// Components
import WalletConnectorForm from "./WalletConnectorForm";
import TabbedComponent from "./TabbedComponent";
import TabbedComponent2 from "./TabbedComponent2";
import Footer from "../../components/organisms/Footer";

// Images
import certifyWhite from "./images/certify-white.svg";
import imageItemsFloating from "./images/items-floating.png";
import imageNftFloating from "./images/nft-claimed-graphic-dc.png";
import imageOpensea02 from "./images/opensea-svg.svg";
import imageSpatial from "./images/spacial-icon-p-500.png";


// CSS
import styles from "./css/MintedTemplate.module.css";

import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";

const BASE_URL_API = process.env.REACT_APP_BASE_URL_API;
const NODE_ENV = process.env.NODE_ENV;



function MintedTemplate(props) {
  // console.log('MintedTemplate:::2022-07-13');
  // console.log('props.polygonscanTransaction:::2022-07-14', props.polygonscanTransaction);

  const walletAddress = '...';
  const polygonscanTransaction = props.polygonscanTransaction;



  function createOpenseaLink() {
    var openseaLink = `https://opensea.io/`;
    if (NODE_ENV === 'development') {
      openseaLink = `https://testnets.opensea.io/`;
    }
    return openseaLink;
  }

  function createPolygonscanLink(polygonscanTransaction) {
    var polygonscanLink = `https://polygonscan.com/tx/${polygonscanTransaction}`;
    if (NODE_ENV === 'development') {
      polygonscanLink = `https://mumbai.polygonscan.com/tx/${polygonscanTransaction}`;
    }
    return polygonscanLink;
  }




  return (
    <>
      <div className={`${styles.section} ${styles.backgroundOverlay}`}>
        <div className={styles.myFrame}>

          <Container className={styles.myContainer}>


            <Row>
              <Col sm={12} md={6}>

                <div className={styles.logoSection}>
                  <Link to="/">
                    <Image src={ certifyWhite }></Image>
                  </Link>
                </div>

              </Col>
              <Col sm={12} md={6}>

                <div className={`${styles.textRight} ${styles.textCenter768}`}>
                  <WalletConnectorForm
                    walletAddress={walletAddress}
                  ></WalletConnectorForm>
                </div>

              </Col>
            </Row>


            <Row>
              <Col>
                <div className={styles.itemsFloating}>
                  <Image src={imageNftFloating} className={styles.itemsFloatingImage}></Image>
                </div>

                <div className="text-center">
                  <h1 className={styles.h1}>Collectible Claimed and ready to view!</h1>
                </div>

                <div className={styles.buttonLinkSection}>

                    <a 
                      href={createOpenseaLink()}
                      target="_blank"
                      className={styles.viewMyCertifyNFts}
                    >
                      <p className={styles.viewMyCertifyNFtsText}>VIEW ON CERTIFY</p>
                    </a>
                    <a 
                      href={createPolygonscanLink(polygonscanTransaction)}
                      target="_blank"
                      className={styles.viewTransactionRecord}
                    >
                      <p className={styles.viewTransactionRecordText}>VIEW TRANSACTION RECORD</p>
                    </a>

                </div>
                <div className={styles.buttonLinkSection2}>
                    <div className={styles.buttonLinkSection3}>
                      <a 
                        href="https://opensea.io/"
                        target="_blank"
                        className={styles.viewMyCertifyNFts}
                      >
                        <p className={styles.viewMyCertifyNFtsText}>VIEW ON CERTIFY</p>
                      </a>
                    </div>
                    <div className={styles.buttonLinkSection3}>
                      <a 
                        href={createPolygonscanLink(polygonscanTransaction)}
                        target="_blank"
                        className={styles.viewTransactionRecord}
                      >
                        <p className={styles.viewTransactionRecordText}>VIEW TRANSACTION RECORD</p>
                      </a>
                    </div>

                </div>

              </Col>
            </Row>


            <Card className={styles.nftClaimSuccess}>
              <Card.Body className={styles.nftClaimSuccessBody}>
                <Row>
                  <Col>

                    <div>
                      <h2 className={`${styles.h2} ${styles.black}`}>How to view and unhide your Digital Collectible on OpenSea</h2>
                      <p className={styles.pDark}>Unhiding your Digital Collectible is free, it allows your token to be seen by other community members and lets others place bids on it.</p>
                    </div>

                    <a 
                      href={createOpenseaLink()} 
                      className={styles.web3Button}
                      target="_blank"
                    >
                      <div className={styles.buttonContentWrapper}>
                        <div className={styles.icoWrapper}>
                          <Image src={imageOpensea02} className={styles.icoWrapperImage}></Image>
                        </div>
                        <p className={styles.web3ButtonText}>OpenSea</p>
                      </div>
                    </a>

                    <TabbedComponent></TabbedComponent>

                    <div style={{height: '60px'}}></div>
                  </Col>
                </Row>
                <Row>
                  <Col>

                    <div>
                      <h2 className={`${styles.h2} ${styles.black}`}>Create your free metaverse gallery with Spatial.io</h2>
                      <p className={styles.pDark}>Display your Digital Collectibles collection in virtual worlds and invite your friends to your space!</p>
                    </div>

                    <a 
                      href={'https://spatial.io/'} 
                      className={styles.web3Button}
                      target="_blank"
                    >
                      <div className={styles.buttonContentWrapper}>
                        <div className={styles.icoWrapper}>
                          <Image src={imageSpatial} className={styles.icoWrapperImage}></Image>
                        </div>
                        <p className={styles.web3ButtonText}>Spatial.io</p>
                      </div>
                    </a>

                    <TabbedComponent2></TabbedComponent2>

                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div style={{height: '20px'}}></div>
                    <p className={styles.pDark}>For more info about your Digital Collectible, visit our <a href="https://certifydc.com/faq" target="_blank">FAQ </a>page.</p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>


          </Container>



        </div>
      </div>
      <Footer></Footer>
    </>
  );
}



export default MintedTemplate;