import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';


import { 
  Col,
  Container,
  Row,
} from "react-bootstrap";
import styles from "./Footer.module.css";

// Images
import certifyLogoWhite from "./images/certify-white.svg";



function Footer() {
  return (
    <Container fluid>
      <Row>
        <Col className="px-0">

          <div className={`
            ${styles.section}
            ${styles.footer}
          `}>

            <div className={`
              ${styles.layoutGrid}
              ${styles.footerLinks}
            `}>

              <div className={`
                ${styles.footerContainer}
                ${styles.node1}
              `}>
                <a href="https://certifydc.com/">
                  <img src={certifyLogoWhite} alt="" className={styles.logo}></img>
                </a>
                <div className={styles.footerText}>Copyright © 2022 Certify™ DC. All rights reserved.</div>
                <div className="social-media-icons-container hide" style={{display: "none"}}>
                  <a href="https://facebook.com" className="social-media-link w-inline-block"><img src="images/facebook.png" alt="" className="social-media-icon"></img></a>
                  <a href="https://instagram.com" className="social-media-link w-inline-block"><img src="images/instagram.png" alt="" className="social-media-icon"></img></a>
                  <a href="https://twitter.com" className="social-media-link w-inline-block"><img src="images/twitter.png" alt="" className="social-media-icon"></img></a>
                </div>
              </div>
              
              <div className={`
                ${styles.footerContainer}
                ${styles.node2}
              `}>
                <div className={styles.footerTitle}>Product</div>
                <a href="https://certifydc.com/" className={styles.footerLink}>Home</a>
                <a href="https://certifydc.com/faq" className={styles.footerLink}>FAQ</a>
              </div>
              <div className={styles.footerContainer}>
                <div className={styles.footerTitle}>company</div>
                <a href="https://certifydc.com/privacy-policy" className={styles.footerLink}>Privacy Policy</a>
                <a href="https://certifydc.com/terms-and-conditions" className={styles.footerLink}>Terms and Conditions</a>
              </div>

            </div>

          </div>


        </Col>
      </Row>
    </Container>
  );
}



export default Footer;
