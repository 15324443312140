import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';


import { 
  Alert,
  Col,
  Container,
  // Image,
  Row,
  Spinner,
} from "react-bootstrap";
import styles from "./MinterTemplate.module.css";

// Images
import certifyLogoWhite from "./images/certify-nft-logo-white.svg";


import Type1Mint from "../../singles/Minter/Type1Mint";
// import Type2 from "./Type2";

import MintedAlready from "../../singles/Minter/MintedAlready";
import ConnectYourWallet from "../../singles/Minter/ConnectYourWallet";
import Footer from "../../organisms/Footer";





function MinterTemplate(props) {
  // console.log('MinterTemplate:::2022-07-06');
  // console.log("props:::2022-07-06", props);


  const isFetching = props.isFetching;
  const store = props.store;
  const product = props.product;
  const order = props.order;
  const nftDetails = props.nftDetails;
  const claimProductResponse = props.claimProductResponse;
  const walletAddress = props.walletAddress;
  const params = props.params;
  
  const connectWalletPressed = props.connectWalletPressed;
  const onChangeWalletAddress = props.onChangeWalletAddress;
  const handleSubmitWalletAddress = props.handleSubmitWalletAddress;

  if ([null, {}, false].includes(product)) {
    return (
      <Container className="p-3">
        <div className={styles.myFrame}>
          <Row>
            <Col className="text-center">
              <h1>Certify NFT</h1>

              <div className="pt-3">
                <Alert>
                  <span className={styles.fontSize14}>Product does not exist!</span>
                </Alert>
              </div>

            </Col>
          </Row>
        </div>
      </Container>
    );
  }


  if (isFetching) {
    return (
      <Container className="p-3">
        <div className={styles.myFrame}>
          <Row>
            <Col>
              <div className="text-center">
                <Spinner animation="grow" variant="primary" />
                <p>LOADING</p>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }


  if (walletAddress === '') {    
    return (
      <>
        <ConnectYourWallet
          connectWalletPressed={connectWalletPressed}
          handleSubmitWalletAddress={handleSubmitWalletAddress}
          onChangeWalletAddress={onChangeWalletAddress}
          walletAddress={walletAddress}
        ></ConnectYourWallet>
        <Footer></Footer>
      </>
    );
  }





  
  var productMinted = product.certify_product_type === 'type 0' && product.payload_mint === null;
  if (productMinted) {
    return (
      <>
        <Type1Mint
          claimProductResponse={claimProductResponse}
          params={params}
          nftDetails={nftDetails}
          product={product}
          walletAddress={walletAddress}
        ></Type1Mint>
        <Footer></Footer>
      </>
    );
  } else {
    return (
      <MintedAlready
        store={store}
        order={order}
        product={product}
      ></MintedAlready>
    );
  }

}



export default MinterTemplate;
