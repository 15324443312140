import React from "react";
import { 
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  Row,
} from "react-bootstrap";

import styles from './ConnectYourWallet.module.css';
import certifyWhite from "./images/certify-white.svg"
import imageItemsFloating from "./images/items-floating.png";
import metamaskIcon from "./images/metamask-icon.png";



function ConnectYourWallet(props) {
  // console.log("ConnectYourWallet:::2022-07-13");
  // console.log("props:::2022-07-13", props);

  const connectWalletPressed = props.connectWalletPressed;
  const handleFormChange = props.handleFormChange;
  const handleSubmitWalletAddress = props.handleSubmitWalletAddress;



  return (
    <Container className="">
      <div className={styles.myFrame}>



        <Row>
          <Col sm={12} md={6}>

            <div className={styles.textCenter768}>
              <Image src={ certifyWhite }></Image>
            </div>

          </Col>
          <Col sm={12} md={6}>

            <div className={`${styles.textRight} ${styles.textCenter768}`}>
            </div>

          </Col>
        </Row>


        <Row>
          <Col>
            <div className={styles.itemsFloating}>
              <Image src={imageItemsFloating} className={styles.itemsFloatingImage}></Image>
            </div>
          </Col>
        </Row>



        <Row>
          <Col>
            <div className="text-center" style={{marginBottom: '40px'}}>
              <h1 className={styles.h1}>Connect your wallet</h1>
            </div>
          </Col>
        </Row>



        <Card className={styles.card1}>
          <Card.Body className={styles.cardBody1}>
            <Row>
              <Col>

                <div>
                  <p className={styles.headerTitle}>Connect your wallet to claim your Certify Digital Collectible.</p>
                  <p className={styles.paragraphDark}>Connect with MetaMask (recommended).</p>

                  <button className={styles.web3Button} onClick={connectWalletPressed}>
                    <div className={styles.web3ButtonContentWrapper}>
                      <div className={styles.web3IconWrapper}>
                        <img src={metamaskIcon} className={styles.web3Icon} alt=""></img>
                      </div>
                      <p className={styles.web3ButtonText}>MetaMask</p>
                    </div>
                  </button>


                  <div className={styles.differentWallet}>
                    <p className={styles.heading5Dark}>Using a different wallet?</p>
                    <p className={styles.paragraphDark}>Claim your Certify Digital Collectible with any digital wallet by pasting the wallet address below.</p>
                    
                    <div style={{paddingTop: '15px'}}></div>
                    
                    <div className={styles.wForm}>
                      <form onSubmit={handleSubmitWalletAddress}>

                        <>
                          <Form.Label
                            htmlFor="pasteWalletAddress"
                            className={styles.paragraphDark}  
                          >Paste wallet address</Form.Label>
                          <Form.Control
                            type="text"
                            id="pasteWalletAddress"
                            name="pasteWalletAddress"
                            className={`
                              ${styles.wInput}
                              ${styles.walletAddressInput} 
                            `} 
                            placeholder="0x09668BD7a1G2f47E61f54AHA445b4224123c767e"
                            onChange={handleFormChange}
                          />
                          <div className="pt-3">
                            <Button 
                              variant="secondary"
                              type="submit"
                              className={`
                                ${styles.wButton}
                                ${styles.continueButton}
                              `}
                            >CONTINUE</Button>
                          </div>
                        </>

                      </form>

   




                      <div 
                        className={`
                          ${styles.successMessage2}
                          ${styles.wFormDone}
                        `}
                        role="region" 
                        aria-label="Connect Wallet Address success"
                      >
                        <div className={styles.successTextBlock}>Wallet Connected. Signing in...</div>
                      </div>
                      <div 
                        className={`
                          ${styles.errorMessage2}
                          ${styles.wFormFail}
                        `}
                        role="region" 
                        aria-label="Connect Wallet Address failure"
                      >
                        <div className={styles.errorTextBlock}>Oops! Something went wrong. Please make sure your wallet address is correct and has no spaces, then try again.</div>
                      </div>
                    </div>
                  </div>


                </div>

              </Col>
            </Row>


          </Card.Body>
        </Card>



      </div>
    </Container>
  );

}

export default ConnectYourWallet;