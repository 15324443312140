import React, { useState } from "react";
import axios from "axios";
import { 
  Image,
  // Col,
} from "react-bootstrap";
import styles from './ImageManagement.module.css';

// Images
// import imageArrowDownIcon from "../../../../pages/Minter/images/arrow_down_icon.png"



const BASE_URL_API = process.env.REACT_APP_BASE_URL_API;



function ImageManagement(props) {
  const store = props.store;
  const product = props.product;

  // State Variables:
  var temp;
  if (product.image_details_automatic) {
    temp = `https://${product.image_details_automatic.base_url}/${product.image_details_automatic.file_path}`;
  } else {
    temp = `...`;
  }
  const [productImage, setProductImage] = useState(parseImageURL(temp));

  const changeShopifyImage = (selectedIndex, e) => {
    // console.log("changeShopifyImage():::2022-07-18");
    // console.log("selectedIndex:::2022-07-18", selectedIndex);

    let temp = `https://${product.shopify_images[selectedIndex.target.selectedIndex].base_url}/${product.shopify_images[selectedIndex.target.selectedIndex].file_path}`;
    setProductImage(parseImageURL(temp));
    props.onShopifyImageChange(temp);

    var data = JSON.stringify({
      selected_index: selectedIndex.target.selectedIndex,
      product_uuid: product.uuid,
    });

    var config = {
      method: 'post',
      url: `${ BASE_URL_API }/api/v0/minter/products/update_image_details_automatic`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };

    axios(config)
    .then(function (response) {
      // console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });


  };


  if (store.fulfilment.image_type === "automatic") {

    // var shopifyImages = product.payload_product.images.map((i, index) => 
    //   <Col xs="6" key={index} className="text-center">
    //     <img src={i.src} width={"100%"}></img>
    //     <p className={styles.fontSize14}>Image { index + 1 }</p>
    //   </Col>
    // );

    var shopifyImageOptions = product.shopify_images.map((i, index) => 
      <option key={index} value={index}>{ `Image ${index + 1}` }</option>
    );

    var imageIndex;
    if (product.image_details_automatic) {
      imageIndex = product.image_details_automatic.position - 1;
    } else {
      imageIndex = 0;
    }

    return (
      <>
  
        <div className=''>

          <div className={styles.nftPreviewHeaderAutomatic}>
            <h5 className={styles.h5}>Select an image for your Digital Collectible.</h5>

            <div className="">
              <select 
                onChange={changeShopifyImage}
                className={styles.imageDropdown}
                defaultValue={(imageIndex)}
              >
                { shopifyImageOptions }
              </select>
            </div>
          </div>
          

          <div className={styles.nftImageContainer}>
            <Image src={productImage} fluid className={styles.itemImagePreview}></Image>

            {/* <div className="pt-3">
              <p className={styles.fontSize14}>
                *Frames in preview are samples only. Once claimed, your NFT will include the details below.
              </p>
            </div> */}
          </div>
        </div>
  
      </>
    )      
  }


  if (store.fulfilment.image_type === "manual") {
    if (product.image_details === null) {
      return (<p className={styles.fontSize14}>Image Pending...</p>)
    }
    const nftImage = product.image_details.file_path;
    return (
      <>
        <div className={styles.nftPreviewHeaderManual}>
          <h5 className={styles.h5Dark}>Digital Collectible preview</h5>
          {/* <p><b>Image URL:</b><span className='text-muted' style={{fontSize: "12px"}}> (Passed from Shopify)</span></p> */}
          {/* <p className={styles.fontSize14}>Image Type: <span className='text-muted' style={{fontSize: "12px"}}> ({ store.fulfilment.image_type })</span></p> */}
          {/* <p className={styles.fontSize14}>{ props.url }</p> */}
          <div className={styles.nftImageContainer}>
            <Image 
              src={`https://${product.image_details.base_url}/${nftImage}`} 
              fluid
              className={styles.itemImagePreview}
            ></Image>
          </div>
          {/* <div>
            <p>
              *Frames in preview are samples only. Once claimed, your NFT will include the details below.
            </p>
          </div> */}
        </div>
      </>
    )
  }
}



function parseImageURL(url) {
  // Prepare product image for NFT:
  if (url) { 
    return url.split('?')[0]
  }
}



export default ImageManagement;