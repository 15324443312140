import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';


import { 
  Alert,
  Col,
  Container,
  Image,
  Row,
} from "react-bootstrap";

import styles from "./MintedAlready.module.css";



function MintedAlready(props) {
  console.log('MintedAlready:::2022-07-06');

  const store = props.store;
  const order = props.order;
  const product = props.product;

  return (
    <>
      <Container className="p-3">
        <div className={styles.myFrame}>

          <Row>
            <Col className='text-center pb-3'>          
              <h1>Certify NFT</h1>
            </Col>
          </Row>



          <Row>
            <Col className=''>
              <div className="text-center">
                <Alert key={"danger"} variant={"danger"}>
                  NFT for this product has already been minted.
                </Alert>
              </div>
            </Col>
          </Row>



          <Row>
            <Col className=''>
              <div>
                <p><b>Claimed!</b></p>
              </div>
            </Col>
          </Row>



          <Row>
            <Col>

              <div>
                <p><b>NFT Image</b></p>
                {/* <p className={styles.fontSize14}>{ product.payload_product.image.src.split('?')[0] }</p> */}
                <div style={{ maxWidth: 300 }}>
                  <Image src={product.payload_product.image.src.split('?')[0]} fluid></Image>
                </div>
                {/* <p>
                *Frames in preview are samples only. Once claimed, your NFT will include the details below.
                </p> */}
              </div>

              <div className='pt-3'>
                <p className="mb-1"><b>NFT Title</b></p>
                <p className={styles.fontSize14}>{ product.payload_product.title }</p>
              </div>

              <div>
                <p className="mb-1"><b>Shop name</b></p>
                <p className={styles.fontSize14}>{ store.payload_shop.name }</p>
              </div>

              <div>
                <p className="mb-1"><b>Date</b></p>
                <p className={styles.fontSize14}>{ order.created_at }</p>
              </div>

              <div>
                <p className="mb-1"><b>Description (Viewable on OpenSea)</b></p>
                <p>{ product.payload_product.body_html.replace(/(<([^>]+)>)/gi, "") }</p>
              </div>

            </Col>
          </Row>

        </div>
      </Container>
    </>
  );


}



export default MintedAlready;
