import React from "react";
import styles from './CustomMetadata.module.css';



function CustomMetadata(props) {

  const store = props.store;
  const product = props.product


  if (store.fulfilment.custom_metadata_type === "automatic") {
    return (
      <>
        <div>
            <p className={styles.attributeTitle}>Custom Metadata</p>
            <p className={`
              ${styles.attributeDescription}
              ${styles.capitalize}
            `}>
              Custom metadata not set.
            </p>
          </div>
      </>
    );    
  }

  if (store.fulfilment.custom_metadata_type === "manual") {
    return (
      <>
        <div>
          <p className={styles.attributeTitle}>Custom Metadata</p>

          <DisplayMetadata
            product={product}
          ></DisplayMetadata>

        </div>

      </>
    );    
  }

}



function DisplayMetadata(props) {
  const product = props.product;

  if (product.custom_metadata === null) {
    return (
      <p className={styles.attributeDescription}>No data</p>
    );
  }

  var myMetadata = product.custom_metadata.map((item, index) => 
    <p 
      key={index} 
      className={`
        ${styles.attributeDescription}
        ${styles.mb0}
      `}
    >{ `Title: ${item.title}, Value: ${item.value}` }</p>
  );

  return (
    <>
      { myMetadata }
    </>
  );
}



export default CustomMetadata;